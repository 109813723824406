var api = require("!C:\\Users\\Vision\\AppData\\Roaming\\nvm\\v16.13.1\\node_modules\\expo-cli\\node_modules\\style-loader\\dist\\runtime\\injectStylesIntoStyleTag.js");
            var content = require("!!C:\\Users\\Vision\\AppData\\Roaming\\nvm\\v16.13.1\\node_modules\\expo-cli\\node_modules\\css-loader\\dist\\cjs.js!./style.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};